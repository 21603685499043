import React, { useState, useEffect } from 'react'
import BorderBox from '../../components/BorderBox'
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@mui/material'
import InputField from '../../components/InputField'
import DateInput from '../../components/DateInput'
import DropdownInput from '../../components/DropdownInput'
import reactotron from '../../ReactronConfig'
import TimePicker from '../../components/TimePicker'
import moment from 'moment'

const ArrivalDetails = ({ bookingData, control, errors, setValue, arrival, setArrival, locationType, locationFrom, locationTo, data, departure, setDeparture }) => {


    const [buffer, setBuffer] = useState('')
    const [bufferList, setBufferList] = useState([])
    const [arrivalMethod, setArrivalMethod] = useState(arrival)

    const [flightArrival, setFlightArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Flight Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Flight Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Flight Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Flight Arrival'

        },
    ])


    const [cruiseArrival, setCruiseArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Cruise Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Cruise Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Cruise Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Cruise Arrival'

        },
    ])
    


   

   

    if(locationFrom?.type === "airport"){
        return(
            <>
            <BorderBox title={"Arrival Details"}>
                <Grid container spacing={.5}>
                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                        <RadioGroup row style={{ paddingTop: 5 }} >
                            <FormControlLabel
                                value="international"
                                control={<Radio
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setArrival('international')
                                        }
                                    }}
                                    checked={arrival === "international"}
                                //name="arival" {...register("arival")} value="international"   
                                />}
                                label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                            />
                            {locationFrom?.countryCode !== "SG" && <FormControlLabel
                                value="domestic"
                                control={<Radio
                                    name="arival"
                                    //{...register("arival")}  
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setArrival('domestic')
                                        }
                                    }}
                                    checked={arrival === "domestic"}
                                />}
                                label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                            />}
                        </RadioGroup>
                        <span style={{ fontSize: 10, color: 'red' }}>{errors?.arival?.message}</span>
                    </Grid>
                    <Grid xs={12} md={3} item sx={{ flexGrow: 1 }}>
                        <InputField
                            fieldLabel="Flight No."
                            fieldName="flight_no"
                            control={control}
                            error={errors?.flight_no}
                        />
                    </Grid> 
                    <Grid xs={12} md={3} sx={{ flexGrow: 1, mt: .5 }}>
                        <DateInput
                            fieldLabel="Flight Date & Time"
                            minDate={new Date()}
                            //maxDate={moment(booking?.returndate).toDate()}
                            fieldName="flight_date"
                            control={control}
                            fieldType="datetime"
                            error={errors?.flight_date}
                            changeDate={(value) => setValue("flight_date", value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3.5} sx={{ flexGrow: 1 }}>
                        <DropdownInput
                            fieldLabel="Buffer Time"
                            fieldName="time_related"
                            control={control}
                            error={errors?.time_related}
                            values={buffer}
                            changeValue={(e) => {
                                setValue('return_time_related', e.target?.value)
                                setBuffer(e.target?.value)
                            }
                            }
                        >
                            <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                            {flightArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                <MenuItem value={val?.value}> {val?.name}</MenuItem>
                            ))
                            }
                        </DropdownInput>
                    </Grid>
                </Grid>
            </BorderBox>
            </>
        )
    }
    else{
        return(
            <BorderBox title={"Arrival Details"}>
            <Grid container spacing={.5} >
                <Grid item xs={12} md={3} sx={{ flexGrow: 1 }} >
                    <DropdownInput
                        fieldLabel="Arrival Method"
                        fieldName="arrival_method"
                        control={control}
                        error={errors.arrival_method}
                        changeValue={e => {
                            setArrival(e.target.value)
                            setArrivalMethod(e.target.value)
                        }}
                    >
                        <MenuItem disabled value="">
                            Select
                        </MenuItem>
                        <MenuItem value="Cruise">Cruise</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                    </DropdownInput>
                </Grid>
                {arrivalMethod === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Name of Cruise"
                        fieldName="cruise_no"
                        control={control}
                        error={errors.cruise_no}
                    />
                </Grid>}
                {arrivalMethod === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                    <TimePicker
                        fieldLabel="Time of Disembarkment"
                        fieldName="disembarkment"
                        control={control}
                        error={errors.disembarkment}
                        changeDate={(value) => setValue("disembarkment", moment(value, 'YYYY-MM-DD h:mm aa').format('h:mm aa'))}
                    />
                </Grid>}
                {arrivalMethod === "Cruise" && <Grid item xs={12} md={3} sx={{ flexGrow: 1 }}>
                    <DropdownInput
                        fieldLabel="Buffer Time"
                        fieldName="time_related"
                        control={control}
                        error={errors.time_related}
                        values={buffer}
                        changeValue={(e) => {
                            setValue('time_related', e.target?.value)
                        }
                        }
                    >
                        <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                        {cruiseArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                            <MenuItem value={val?.value}> {val?.name}</MenuItem>
                        ))}
                    </DropdownInput>
                </Grid>}
            </Grid>
            </BorderBox>
        )
    }

    
}

export default ArrivalDetails