import React, { useState, useEffect } from 'react'
import BorderBox from '../../components/BorderBox'
import DropdownInput from '../../components/DropdownInput'
import { Button, Divider, Grid, MenuItem, Typography } from '@mui/material'
import PlacesInput from '../../components/PlacesInput'
import DateInput from '../../components/DateInput'
import CountInput from '../../components/CountInput';
import SelectPassenger from '../../components/SelectPassenger'
import InputField from '../../components/InputField'
import AutoComplete from '../../components/AutoComplete'
import moment from 'moment'
import reactotron from '../../ReactronConfig'
import { getDirection } from '../../helper/geoCode'

const TravelDetails = ({ control, errors, getValues, setValue, data, setError, changePricePlan, location_from, location_to, setLocationFrom, setLocationTo, setBooking, bookingData, typeChanged, suggList }) => {


    const [open, setOpen] = useState(false)
    const [type, setType] = useState(data?.type);




    useEffect(() => {
        if(data){
            setValue("type", data?.type)
            setType(data?.type)
        }
        
    }, [data])
    

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const serviceTypeChange = (e) => {

        let bookings = {
            ...bookingData,
            type: e.target?.value
        }
        setBooking(bookings)
        setValue("type", e.target?.value)
        typeChanged(e.target?.value)

        if(e.target?.value === "hour"){
            if(location_from?.countryCode === "SG"){
                setValue("duration", "3")
            }

            //setLocationTo(null)
            setValue("location_to", null);
        }
        else{
            //setLocationTo(null)
        }
        setType(e.target?.value)

        changePricePlan()
    }


    const locationChange = (value) => {
        if(type === "point"){
            setValue("duration", null)
        }
        else{
            if(value?.countryCode === "SG"){
                setValue("duration", "3")
            }
            else{
                setValue("duration", "3")
            }
            
        }
        changePricePlan()
        
    }


    const durationChange = (e) => {
        setValue("duration", e.target.value)
        changePricePlan()
    }

    const locationToChange = async(value) => {
        await setLocationTo(value)
        await setValue("location_to", value)
        setError("location_to", '')
       
        changePricePlan()
    }


    const renderDetailsByType = () => {
        if(type === "point"){
            return(
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <AutoComplete
                        placeholder="Drop Off Location"
                        fieldName="location_to"
                        control={control}
                        error={errors.location_to}
                        values={location_to}
                        onPlaceChange={locationToChange}
                        suggList={suggList}
                    />
                </Grid>
            )
        }
        else if(type === "hour"){
            if(location_from?.countryCode === "SG"){
                return(
                    <Grid item xs={12} md={6} sx={{ flexGrow: 1 }} >
                        <InputField
                            fieldName="duration"
                            fieldLabel={data?.location_from?.countryCode === "SG" ? "Duration In Hour(Minimum 3 Hours)" : "Duration In Hour"}
                            placeholder="Duration In Hour"
                            fontSize="14px"
                            control={control}
                            readonly={false}
                            error={errors.duration}
                            fieldChange={durationChange}
                        />
                    </Grid>
                )
            }
            else{
                return(
                    <>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                            <AutoComplete
                                placeholder="Drop Off Location"
                                fieldName="location_to"
                                control={control}
                                error={errors.location_to}
                                values={location_to}
                                onPlaceChange={locationToChange}
                                suggList={suggList}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }} >
                            <InputField
                                fieldName="duration"
                                fieldLabel={data?.location_from?.countryCode === "SG" ? "Duration In Hour(Minimum 3 Hours)" : "Duration In Hour"}
                                placeholder="Duration In Hour"
                                fontSize="14px"
                                control={control}
                                readonly={false}
                                error={errors.duration}
                                fieldChange={durationChange}
                            />
                        </Grid>
                    </>
                )
            }
        }
    }


    const getPasengers = () => {
        let data = getValues();
        const { adults, baby, minors, childrens} = data
        setValue("passenger", adults + baby + minors + childrens);
        changePricePlan()
        handleClose()
    }


    return (
        <BorderBox title={"Trip"}>
            <Grid container spacing={.5} >
                {data?.type && <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <DropdownInput
                        fieldLabel="Service Type"
                        fieldName="type"
                        control={control}
                        error={errors.type}
                        values={getValues("type")}
                        changeValue={serviceTypeChange}
                    >
                        <MenuItem disabled value="" className='formText'>
                            Select
                        </MenuItem>
                        <MenuItem  value="point" className='formText'>
                            Point To Point
                        </MenuItem>
                        <MenuItem value="hour" className='formText'>
                            Hourly
                        </MenuItem>
                    </DropdownInput>
                </Grid>}
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <AutoComplete
                        placeholder="Pick Up Location"
                        fieldName="location_from"
                        control={control}
                        error={errors?.location_from}
                        values={location_from}
                        suggList={suggList}
                        onPlaceChange={(value) => {
                            setValue("location_from", value)
                            setError('location_from', '')
                            setLocationFrom(value)
                            locationChange(value)
                        }}
                    />
                </Grid>
                {renderDetailsByType()}
                
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <DateInput
                        fieldLabel="Pick Up Date & Time"
                        fieldName="pickupdate"
                        //marginRight={20}
                        control={control}
                        mode={'hour'}
                        error={errors.pickupdate}
                        fieldType="datetime"
                        // minDate={new Date(Date.now() + ( 3600 * 1000 * 24)).toISOString().slice(0, 16)}
                        minDate={new Date()}
                        minTime={new Date().getTime()}
                        changeDate={(value) => {
                            //setReturnMinDate(moment(value).add(1, 'hour').toDate())
                            setValue("pickupdate", value)
                            changePricePlan()
                            //console.log({value: moment(value).utcOffset(getValues("location_from").utcOffset).utc().format("hh:mm A")})
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <SelectPassenger
                        fieldLabel="Passenger Details"
                        control={control}
                        fieldName="passenger"
                        fontSize="14px"
                        width={"100%"}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        values={getValues().passenger}
                    >
                        <CountInput
                            fieldLabel="Adults"
                            fieldName="adults"
                            error={errors.adults}
                            width='30ch'
                            fontSize="12px"
                            onChange={(value) => setValue("adults", value)}
                            minValue={1}
                            values={getValues().adults}
                        />
                        <Divider alignment='vertical' variant='middle' />
                        <CountInput
                            fieldLabel="Baby Seats (0 to 12 Months)"
                            fieldName="baby"
                            error={errors.baby}
                            width='30ch'
                            fontSize="12px"
                            onChange={(value) => setValue("baby", value)}
                            minValue={0}
                            values={getValues("baby")}
                        />
                        <Divider alignment='vertical' variant='middle' />
                        <CountInput
                            fieldLabel="Child Seats (1 to 2 Yrs)"
                            fieldName="childrens"
                            error={errors.childrens}
                            width='30ch'
                            fontSize="12px"
                            onChange={(value) => setValue("childrens", value)}
                            minValue={0}
                            values={getValues("childrens")}
                        />
                        <Divider alignment='vertical' variant='middle' />
                        <CountInput
                            fieldLabel="Booster Seats (3 to 4 Yrs)"
                            fieldName="minors"
                            error={errors.minors}
                            width='30ch'
                            fontSize="12px"
                            onChange={(value) => setValue("minors", value)}
                            minValue={0}
                            values={getValues("minors")}
                            borderBottom={{ xs: 0, md: "2px solid #F6F6F6 " }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                type="button"
                                variant="filled"
                                //onClick={resetPasengers}
                                style={{ backgroundColor: '#FFFFFF', borderRadius: 4, height: 'auto', width: 'auto', border: '1px solid #333333' }}>
                                <Typography className='resetButton' >RESET</Typography>
                            </Button>
                            <span style={{ padding: 2 }} />
                            <Button
                                type="button"
                                variant="filled"
                                onClick={getPasengers}
                                style={{ backgroundColor: '#333333', borderRadius: 4, height: 'auto', width: 'auto' }}>
                                <Typography className='buttonText' >APPLY</Typography>
                            </Button>
                        </div>
                    </SelectPassenger>
                </Grid>
                <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
                    <InputField
                        fieldLabel="Special Instructions"
                        fieldName="specialinstructions"
                        control={control}
                        multiline={true}
                        rows={1}
                        error={errors.specialinstructions}
                    />
                </Grid>
            </Grid>
        </BorderBox>
    )
}

export default TravelDetails